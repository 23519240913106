import React from 'react'; 
const Image = (props) => {
  return(
      <div>
          <img
          src={props.url} 
          effect="opacity"
          alt={props.alt}
          loading="lazy"
          />
      </div>
      
  );
}; 
export default Image;