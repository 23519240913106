import React from 'react'; 
import * as Constants from '../../../constants';
import Image from '../../Images/Image/Image';
import classes from './Event.module.css';
const Event = (props) => {
  // const imagePath = process.env.REACT_APP_TEST_VAR === 'dev' ? Constants.dev.imagesURL: Constants.prod.imagesURL;
  // const image = props.image ? <Image url={imagePath+props.image} />  : '';
  const date = new Date(props.inici);
  const end_date = new Date(props.final);
  const dateFormated = new Intl.DateTimeFormat('en-GB').format(date);
  const endDateFormated = new Intl.DateTimeFormat('en-GB').format(end_date);
  return(
      <div className={classes.Item}>
          <div className={classes.Image}>
            { props.imatge ? <Image url={props.imatge.url} />  : '' }
          </div>
          
          <div className={classes.Info}>
            {/* <div className="title">
              <h4>{props.title}</h4>
            </div> */}
            <div>
              {props.title}
            </div>
            <div className={classes.Date}>
              <span>{ dateFormated }</span> - <span>{ endDateFormated }</span>
            </div>
            
            {/* <p>{props.date.toLocaleDateString()}</p> */}
            
            
          </div>
          
      </div>
  );
}; 
export default Event;