import React from 'react'; 
import classes from './NavCategories.module.css';
const NavCategories = (props) => {
  const href = window.location.href;
  let items = props.categories.map(item => {
    return <a href={`/category/${item.id}`} class={`${href.includes(item.id) ? classes['active']: ''}`}>{item.titol}</a>
  })
  return(
      <div className={classes.NavItem}>
         { items }
      </div>
      
  );
}; 
export default NavCategories;