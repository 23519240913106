import React from 'react'; 
import Event from '../Event/Event';
import classes from './GroupEvents.module.css';

const GroupEvents = (props) => {
    let listEvents = Object.entries(props.listevents).map(item => {
        return <Event title={item[1].titol} imatge={item[1].imatge} inici={item[1].inici} final={item[1].final} />
    });

    return(
        <div>
           <h3>{props.title}</h3>
           <div className={classes.GridBlock}>
                { listEvents }
           </div>
           
       
        </div>
    );
  }; 

export default GroupEvents;