import React, { Component } from 'react'; 
import axios from 'axios';
import FilterCategory from './FilterCategory/FilterCategory';
import Helmet from 'react-helmet';
import Header from '../../components/Header/Header';

class Images extends Component{
    state = {
        categories: []
    };

    componentDidMount(){
        axios({
            url: 'https://graphql.datocms.com/',
            method: 'post',
            headers: {
              Authorization: 'd269801975eea4d5dda51f4cffb147'
            },
            data: {
                query: `
                query{
                  allCategoriaImatges{
                    id
                    titol
                    imatge{
                      url
                    }
                  }
                }`
            }
        }).then((result) => {
            const results = result.data.data.allCategoriaImatges.map((item, index) => {
                return item;
            })
            console.log(results)
            this.setState({
                categories: results
            })
        });

    }
    render(){
        return(
            <div>
                <Helmet>
                    <title>Galeria</title>
                    <meta name="description" content="Galeria de tatuajes de barbero tattoo, tatuador de barcelona de estilo único y caracteristico, mezcla de realismo, trash polka , sketch y avangarde" />
                </Helmet>
                <Header customClass={window.location.pathname == '/' ? 'Headerhome': ''}/>
                <FilterCategory categories={this.state.categories}/>
            </div>
            
        );
    }
}; 
export default Images;