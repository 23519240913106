import React, { Component } from 'react'; 
import Header from '../../components/Header/Header';
import parse from 'html-react-parser';
import axios from 'axios';
import * as Constants from '../../constants';
import Image from '../../components/Images/Image/Image';

class Faqs extends Component{
    state = {
        faqs: []
    }
    toggle(index){
        let newStates = [...this.state.faqs];
        newStates.map((item, i) => {
            if(i == index){
                newStates[i].open = !this.state.faqs[i].open 
            }else{
                newStates[i].open = false;
            }
        })
        this.setState({
            faqs: newStates
        });
    }
    componentDidMount(){
        axios({
                url: 'https://graphql.datocms.com/',
                method: 'post',
                headers: {
                  Authorization: 'd269801975eea4d5dda51f4cffb147'
                },
                data: {
                  query: `
                    query{
                      faqPage{
                        faqsItem{
                          titol
                          text
                        }
                      }
                    }
                    `
                }
              }).then((result) => {
                let faqsResult = result.data.data.faqPage.faqsItem.map(item => {
                    return {title: item.titol, text: item.text, open: false}
                }) 
                this.setState({
                    faqs: faqsResult
                })
              });
    }
    render(){
        // const imagePath = process.env.REACT_APP_TEST_VAR === 'dev' ? Constants.dev.imagesURL: Constants.prod.imagesURL;
        let faqsRender = this.state.faqs.map((item, index) =>{
          return <div className={'item ' + (item.open ? 'open' : '')} key={index}><div className="title" onClick={() => this.toggle(index) }>{item.title}</div><div className="text">{parse(item.text)}</div> </div>;
        });
        console.log(this.state.faqs)
        return(
            <div>
                <Header customClass={window.location.pathname == '/' ? 'Headerhome': ''}/>
                <div className="container">
                    <div className="listfaqs">
                        { faqsRender }
                    </div>
                </div>
            </div>
        )
    }
}

export default Faqs;