import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import * as Constants from './constants';

axios.defaults.baseURL =  process.env.REACT_APP_TEST_VAR === 'dev' ? Constants.dev.graphql: Constants.prod.graphql;

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
