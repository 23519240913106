import React, { Component } from 'react'; 
import axios from 'axios';
import * as Constants from '../../../constants';
import { Link } from 'react-router-dom';
import classes from './GridImages.module.css';
import Masonry from 'react-masonry-css';
import Image from '../Image/Image';
import NavCategories from '../NavCategories/NavCategories';
import Header from '../../../components/Header/Header';

class GridImages extends Component{
    state = {
        posts: [],
        categories: []
    };
    breakpointColumnsObj = {
        default: 3,
        1100: 3,
        700: 2,
    };
    filterCategory = (event) => {
        axios({
            url: 'https://graphql.datocms.com/',
            method: 'post',
            headers: {
              Authorization: 'd269801975eea4d5dda51f4cffb147'
            },
            data: {
                query: `
                query{
                    allGaleriaImatges(first: "100", orderBy: _createdAt_DESC){
                        titol
                        imatge{
                          url
                        }
                    }
                }
                `
            }
        }).then((result) => {
            const results = result.data.data.allGaleriaImatges.map(item => {
                return item;
            })
            console.log(results)
            this.setState({
                posts: results
            })
        });
    }
    componentDidMount(){
        if (this.props.match.params.id){
            axios({
                url: 'https://graphql.datocms.com/',
                method: 'post',
                headers: {
                Authorization: 'd269801975eea4d5dda51f4cffb147'
                },
                data: {
                    query: `
                    query{
                        allGaleriaImatges(filter: {categoria: {eq: "${this.props.match.params.id}"}}, first: "100", orderBy: _createdAt_DESC) {
                            titol
                            id
                            imatge{
                              url
                            }
                          }
                    }
                    `
                }
            }).then(result => {
                console.log(result)
                    const results = result.data.data.allGaleriaImatges.map(item => {
                        return item;
                    })
                    this.setState({
                        posts: results
                    })
            })
        }
        axios({
            url: 'https://graphql.datocms.com/',
            method: 'post',
            headers: {
              Authorization: 'd269801975eea4d5dda51f4cffb147'
            },
            data: {
                query: `
                query{
                    allCategoriaImatges(first: "100"){
                        id
                        titol
                        imatge{
                            url
                        }
                    }
                }`
            }
        }).then((result) => {
            const results = result.data.data.allCategoriaImatges.map((item, index) => {
                return item;
            })
            this.setState({
                categories: results
            })
        });
    }
    render(){
        //const imagePath = process.env.REACT_APP_TEST_VAR === 'dev' ? Constants.dev.imagesURL: Constants.prod.imagesURL;
        let imagesRender = this.state.posts.map(item => {
            return <Link to={'/imagenes/' + item.id} key={item.id}>
                    <Image 
                    url={item.imatge.url} 
                    alt={item.titol}
                    />
                </Link>
        })
        
        return(
            <div>
                <Header customClass={window.location.pathname == '/' ? 'Headerhome': ''}/>
                <NavCategories filterCategories={this.filterCategory} categories={this.state.categories}/>
                <div className="container">
                    <div>
                            <Masonry
                            breakpointCols={this.breakpointColumnsObj}
                            className={classes.Masonry}
                            columnClassName="my-masonry-grid_column">
                                {imagesRender}
                            </Masonry>
                    </div>
                </div>
                
            </div>
           
        )
    }
}

export default GridImages;