import React from 'react'; 
import { Link } from 'react-router-dom';
import logoRuben from '../../assets/images/logo-blanco.svg';
const Logo = () => {
  return(
      <div>
          <Link to="/"><span>Barbero</span><img src={logoRuben} alt="Logo Barbero Tatto" /><span>Tattoo</span></Link>
      </div>
  );
}; 
export default Logo;