import React, { Component } from 'react'; 
import axios from 'axios';
import classes from './FullImage.module.css';
import * as Constants from '../../../constants';
import Close from '../../../assets/images/close.svg';
class FullImage extends Component{
  state = {
    image: [],
    showImage: false
  };
  redirectBackHandler = () => {
    this.setState({
      showImage: false
    }, this.props.history.goBack())
  }
  componentDidMount(){
    if (this.props.match.params.id){
      axios({
          url: 'https://graphql.datocms.com/',
          method: 'post',
          headers: {
            Authorization: 'd269801975eea4d5dda51f4cffb147'
          },
            data: {
              query: `
                query{
                  galeriaImatge(filter: {id: {eq: "${this.props.match.params.id}"}}) {
                    titol
                    imatge{
                      url
                    }
                  }
                }
                `
            }
          }).then((result) => {
            console.log(result)
            this.setState({
              image: [result.data.data.galeriaImatge.imatge.url, result.data.data.galeriaImatge.titol],
              showImage: true
            })
          }).catch(e =>{
            console.error(e);
          });
    }
  }
  render(){
    //const imagePath = process.env.REACT_APP_TEST_VAR === 'dev' ? Constants.dev.imagesURL: Constants.prod.imagesURL;
    return(
      <div className={classes.FullImage} onClick={this.redirectBackHandler}>

          <div className={ classes.FullImage }>
            <div className={ classes.Close }><img src={ Close } /></div>
            <img alt={ this.state.image[1] } src={this.state.image[0]} />
            <div className={classes.Title}>{ this.state.image[1] }</div>
          </div>
        
      </div> 

    );
  }
  
}; 
export default FullImage;