import React, { useEffect, useState } from 'react';
import './App.css';
import Home from './components/Home/Home';
import Images from './components/Images/Images';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Events from './components/Events/Events';
import FullImage from './components/Images/FullImage/FullImage';
import NotFound from './components/NotFound/NotFound';
import LinksFooter from './components/LinksFooter/LinksFooter';
import GridImages from './components/Images/GridImages/GridImages';
import About from './components/About/About';
import Faqs from './components/Faqs/Faqs';


function App() {
  
  return (
    <BrowserRouter>
      <div className="App">
        <main>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/about" exact component={About} />
            <Route path="/imagenes" exact component={Images} />
            <Route path="/eventos" exact component={Events} />
            <Route path="/category/:id" exact component={GridImages}/>
            <Route path="/imagenes/:id" exact component={FullImage}/>
            <Route path="/faqs" exact component={Faqs}/>
            <Route component={NotFound} />
          </Switch>
          
        </main>
        <footer>
          <LinksFooter />
        </footer>
      </div>
    </BrowserRouter>
    
  );
}

export default App;
