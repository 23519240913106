import React, { Component } from 'react'; 
import axios from 'axios';
import parse from 'html-react-parser';
import BlockText from './BlockText/BlockText';
import classes from './About.module.css';
import Helmet from 'react-helmet';
import Header from '../../components/Header/Header';

class About extends Component{
    
    state = {
        columns: []
    }

    componentDidMount(){
        axios({
                url: 'https://graphql.datocms.com/',
                method: 'post',
                headers: {
                  Authorization: 'd269801975eea4d5dda51f4cffb147'
                },
                data: {
                  query: `
                      {
                        info{
                            columnes{
                              id
                              text
                              imatge{
                                url
                              }
                            }
                        }
                    }
                    `
                }
              }).then((result) => {
                console.log(result)
                this.setState({
                  columns: result.data.data.info.columnes
                })
                // this.setState({
                //     text1: result.data.data.allMainPage[0].firstCol,
                //     text2: result.data.data.allMainPage[0].secondCol,
                //     text3: result.data.data.allMainPage[0].thirdCol,
                //     image1: result.data.data.allMainPage[0].firstImage,
                //     image2: result.data.data.allMainPage[0].secondImage,
                //     image3: result.data.data.allMainPage[0].thirdImage,
                // })
              });
    }

    render(){
        return(
            <div className={classes.Home}>
                <Helmet>
                    <title>Home</title>
                    <meta name="description" content="Página web dedicada al trabajo de Rubén Barbero (barbero tattoo), donde encontrar sus tatuajes y diseños, viajes, ideas y convenciones"/>
                </Helmet>
                <Header customClass={window.location.pathname == '/' ? 'Headerhome': ''}/>
                <div className="container">
                    {/* <BlockText text={parse(this.state.text1)} image={this.state.image1}/>
                    <BlockText text={parse(this.state.text2)} image={this.state.image2}/>
                    <BlockText text={parse(this.state.text3)} image={this.state.image3}/> */}
                    {
                      this.state.columns.map(item => {
                        return (
                          <BlockText text={parse(item.text)} image={item.imatge?.url}/>
                        )
                      })
                    }
                </div>
                
                
            </div>
           
        );
    }
  
}; 
export default About;