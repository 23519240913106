import React from 'react'; 
import { NavLink } from 'react-router-dom';
const Nav = () => {
  return(
      <nav className="Navigation">
          <ul>
              
              <li>
                <NavLink 
                to="/" exact>Home</NavLink>
              </li>

              <li>
                <NavLink 
                to="/about" exact>+ info</NavLink>
              </li>
              
              <li>
                <NavLink 
                to="/imagenes">Galeria</NavLink>
              </li>
              <li>
              <NavLink to="/eventos">Eventos</NavLink>
              </li>
              <li>
              <NavLink to="/faqs">Faqs</NavLink>
              </li>
             
          </ul>
      </nav>
  );
}; 
export default Nav;