import React from 'react';
import * as Constants from '../../../constants';
const BlockText = (props) => {
  //const imagePath = process.env.REACT_APP_TEST_VAR === 'dev' ? Constants.dev.imagesURL: Constants.prod.imagesURL;
  return(
      <div>
          {props.text}
          { props.image ?  <img src={props.image} /> : ''}
         
      </div>
  );
}; 
export default BlockText;