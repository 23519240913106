import React, { Component }  from 'react'; 
import GroupEvents from './GroupEvents/GroupEvents';
import axios from 'axios';
import Helmet from 'react-helmet';
import classes from './Events.module.css';
import Header from '../../components/Header/Header';

class Events extends Component{

    state = {
        events: [],
        pastEvents:[],
        filters: [
            {name: 'Proximos', id:2},
            {name: 'Anteriores', id:3}
        ]
    }

    componentDidMount(){
      let date = new Date();
      let day = String(date.getDate()).padStart(2, '0');
      let month = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
      let year = date.getFullYear();

      let currentDate = year + '-' + month + '-' + day;
        axios({
            url: 'https://graphql.datocms.com/',
            method: 'post',
            headers: {
              Authorization: 'd269801975eea4d5dda51f4cffb147'
            },
            data: {
                query: `
                query{
                  allEsdeveniments(orderBy: inici_ASC, filter: {inici: {gte: "${currentDate}"}}){
                    id
                    titol
                    ciutat{
                      id
                      nom
                    }
                    inici
                    final
                    imatge{
                      url
                    }
                  }
                }
                `
            }
            }).then((result) => {
  
            const results = result.data.data.allEsdeveniments.map(item => {
                return item;
            })
            console.log(results)
            this.setState({
                events: results
            })
            });
    }
    render(){

        const groupBy = (objectArray, property) => {
            return objectArray.reduce(function (total, obj) {
                let key = obj[property].nom;
                if (!total[key]) {
                    total[key] = [];
                }
                total[key].push(obj);
                return total;
            }, {});
        }

        let groupedCityFuture = groupBy(this.state.events, 'ciutat');
        let eventsRender = Object.entries(groupedCityFuture).map(item => {
            return <GroupEvents title={item[0]} listevents={item[1]} key={item[0]}/>
        })
        

        // let groupedCityPast = groupBy(this.state.pastEvents, 'city');
        

        return(
            <div>
                <Helmet>
                    <title>Eventos</title>
                    <meta name="description" content="Eventos, viajes, convenciones de tatuajes ( tattoo shows ) y guest proximos de barbero tattoo. Puedes reservar cita y solicitar mas informacion" />
                </Helmet>
                <Header customClass={window.location.pathname == '/' ? 'Headerhome': ''}/>
                <div className="container">
                    <h3 className={classes.Title}>Proximos eventos</h3>
                    <div className={classes.GridEvents}>
                         { eventsRender }
                    </div>

                    {/* <h3 className={classes.Title}>Eventos anteriores</h3>
                    <div className={classes.GridEvents}>
                        { pastEventsRender }
                    </div> */}
                   
                </div>
            </div>
            
        );
    }
     
}; 
export default Events;