import React, { Component } from 'react'; 
import classes from './FilterCategory.module.css';
import { Link } from 'react-router-dom';
import * as Constants from '../../../constants';
class FilterCategory extends Component{
    render(){
        const output = this.props.categories.map(item =>{
        return <Link to={'/category/' + item.id} key={item.id}><div><h3>{item.titol}</h3><img src={item.imatge.url } alt={item.titol}/></div></Link>;

        })
    return(
        <div className={classes.Filter}>
            <div className="container">
                <div className={classes.Grid}>
                    {output}
                </div>
            </div>
            
        </div>
    )
    }
    
}; 
export default FilterCategory;