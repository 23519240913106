import React, { Component } from 'react'; 
import classes from './Home.module.css';
import Helmet from 'react-helmet';
import axios from 'axios';
import instagramIcon from '../../assets/images/icono-instagram-black.svg';
import whatsappIcon from '../../assets/images/icono-whatsapp-black.svg';
import facebookIcon from '../../assets/images/facebook-icon.svg';
import mailIcon from '../../assets/images/mail-icon.svg';
import Header from '../../components/Header/Header';
import * as Constants from '../../constants';

// api token d269801975eea4d5dda51f4cffb147
class Home extends Component{
    state = {
        image: '',
        imageMobile: ''
    }
    componentDidMount(){
        axios({
                url: 'https://graphql.datocms.com/',
                method: 'post',
                headers: {
                  Authorization: 'd269801975eea4d5dda51f4cffb147'
                },
                data: {
                  query: `
                      {
                        home{
                            imatge{
                                url
                            }
                        }
                    }
                    `
                }
              }).then((result) => {
                const image = result.data.data.home.imatge.url
                let imageMobile = result.data.data.home.imatgeMobile?.url ? result.data.data.home.imatgeMobile.url : image
                this.setState({
                    image,
                    imageMobile
                })
              });
    }
    render(){
        //const imagePath = process.env.REACT_APP_TEST_VAR === 'dev' ? Constants.dev.imagesURL: Constants.prod.imagesURL;
        return(
            <div className={classes.Home}>
                <Helmet>
                    <title>Home</title>
                    <meta name="description" content="Página web dedicada al trabajo de Rubén Barbero (barbero tattoo), donde encontrar sus tatuajes y diseños, viajes, ideas y convenciones"/>
                </Helmet>
                <Header customClass={window.location.pathname == '/' ? 'Headerhome': ''}/>
                <div className="container">
                    <div className={classes.ImageHome}>
                    <picture>
                        <source 
                            media="(min-width: 768px)" srcSet={this.state.image} 
                        />
                        <source 
                            media="(max-width: 768px)" srcSet={this.state.imageMobile} 
                        />
                        <img 
                            src={this.state.image}
                            alt="home"
                        />
                        </picture>
                    </div>
                    <div className={classes.Icons}>
                        <div><a href="https://www.instagram.com/barbero_tattoo/"><img src={instagramIcon} /></a> </div>
                        <div><a href="https://wa.me/34644103409">< img src={whatsappIcon} /></a></div>
                        <div><a href="https://www.facebook.com/barberotattoo/"><img src={facebookIcon} /></a> </div>
                        <div><a href="mailto:barberotattoos@gmail.com"><img src={mailIcon} /></a></div>
                    </div>
                  
                </div>
                
            </div>
        );
    }
  
}; 
export default Home;
